<template>
	<v-container
		class="pa-0 bg_grey"
		fluid
	>
		<v-col
			class="pa-0"
			cols="12"
		>
			<div class="new_wrapper">
				<v-row class="ma-0 py-0 py-md-5 py-sm-5">
					<!-- 1열 -->
					<v-col
						class="pa-0"
						md="4"
						sm="6"
						:class="broswersizing === 'mobile' ? 'px-0' : 'px-3'"
					>
						<!-- 롤링배너 -->
						<HomeMainCarousels
							:carousel-items="HOME_GET_MAIN_BANNER"
							height="auto"
						/>
						<!-- 베스트제품 -->
						<HomeMainNewBest
							:items="HOME_GET_MAIN_BEST_PRODUCT"
							class="my-4"
						/>
						<!-- 빠른견적내기 -->
						<HomeCommunityHomeNewRapidcalc />
						<!-- 마루추천서비스 -->
						<!-- <HomeCommunityHomeNewRecommend /> -->
						<!-- 케어플로(태블릿용) -->
						<HomeCommunityHomeNewCareflo v-if="broswersizing === 'tablet'" />
						<!-- 품질보증등록 및 온라인카탈로그 -->
						<v-row
							v-if="broswersizing === 'tablet'"
							class="ma-0 mt-3 pb-4 mt-sm-4 mt-md-4"
						>
							<!-- 품질보증 배너 -->
							<v-col
								class="pa-0"
								cols="12"
								md="12"
								@click="$router.push('/custom/quality')"
							>
								<div class="mr-0 mr-md-0 mb-4 circle_wrap">
									<v-row class="ma-0 py-3 px-3 px-sm-8 banner_quality">
										<v-col
											class="pa-0"
											cols="9"
										>
											<p class="tit">품질보증 등록</p>
											<p class="desc color_gray_6">최대 10년 품질보증 서비스</p>
										</v-col>
										<v-col
											class="pa-0 tawr"
											cols="3"
										>
											<v-img
												:src="require('@/assets/img/home/custom/icon_10years.png')"
												eager
											/>
										</v-col>
									</v-row>
								</div>
							</v-col>

							<!-- 카달로그배너 -->
							<v-col
								class="pa-0 mt-3 mt-sm-0 mt-md-0"
								cols="12"
								md="12"
								@click="$router.push('/site/catalog')"
							>
								<div class="ml-0 ml-md-0 circle_wrap">
									<v-row class="ma-0 py-3 px-3 px-sm-8 banner_cadalog">
										<v-col
											class="pa-0 tawr"
											cols="3"
										>
											<v-img
												:src="require('@/assets/img/home/main/icon_catalog.svg')"
												eager
											/>
										</v-col>
										<v-col
											class="pa-0 catalog_txt"
											cols="9"
										>
											<p class="tit">온라인 카탈로그</p>
											<p class="desc color_gray_6">동화의 전 제품을 만나보세요</p>
										</v-col>
									</v-row>
								</div>
							</v-col>
						</v-row>
					</v-col>

					<!-- 2열 -->
					<v-col
						class="pa-0"
						md="4"
						sm="6"
						:class="broswersizing === 'mobile' ? 'px-0' : 'px-3'"
						:style="broswersizing === 'mobile' ? 'flex-basis: auto' : ''"
					>
						<!-- 케어플로 -->
						<HomeCommunityHomeNewCareflo v-if="broswersizing !== 'tablet'" />
						<!-- 품질보증등록 및 온라인카탈로그 -->
						<v-row
							v-if="broswersizing !== 'tablet'"
							class="ma-0 mt-3 pb-4 mt-sm-4 mt-md-4"
						>
							<!-- 품질보증 배너 -->
							<v-col
								class="pa-0"
								cols="12"
								md="12"
								@click="$router.push('/custom/quality')"
							>
								<div class="mr-0 mr-md-0 mb-4 circle_wrap">
									<v-row class="ma-0 py-3 px-3 px-sm-8 banner_quality">
										<v-col
											class="pa-0"
											cols="9"
										>
											<p class="tit">품질보증 등록</p>
											<p class="desc color_gray_6">최대 10년 품질보증 서비스</p>
										</v-col>
										<v-col
											class="pa-0 tawr"
											cols="3"
										>
											<v-img
												:src="require('@/assets/img/home/custom/icon_10years.png')"
												eager
											/>
										</v-col>
									</v-row>
								</div>
							</v-col>

							<!-- 카달로그배너 -->
							<v-col
								class="pa-0 mt-md-0"
								cols="12"
								md="12"
								@click="$router.push('/site/catalog')"
							>
								<div class="ml-0 ml-md-0 circle_wrap">
									<v-row class="ma-0 py-3 px-3 px-sm-8 banner_cadalog">
										<v-col
											class="pa-0 tawr"
											cols="3"
										>
											<v-img
												:src="require('@/assets/img/home/main/icon_catalog.svg')"
												eager
											/>
										</v-col>
										<v-col
											class="pa-0 catalog_txt"
											cols="9"
										>
											<p class="tit">온라인 카탈로그</p>
											<p class="desc color_gray_6">동화의 전 제품을 만나보세요</p>
										</v-col>
									</v-row>
								</div>
							</v-col>
						</v-row>
						<!-- 전문가집들이 -->
						<HomeCommunityHomeNewProfesional />
						<!-- 3D집들이 -->
						<!-- <HomeCommunityHomeNewThreehouse /> -->
						<!-- 마루추천서비스 -->
						<HomeCommunityHomeNewRecommend />

						<!-- 추천태그(태블릿용) -->
						<HomeMainNewCategory
							v-if="broswersizing === 'tablet'"
							:items="HOME_GET_MAIN_SEARCH_TAGS"
						/>
						<!-- 마루잘알TV (태블릿용)-->
						<HomeCommunityHomeNewMaruTv v-if="broswersizing === 'tablet'" />
						<!-- 노하우(태블릿용) -->
						<HomeCommunityHomeNewKnowhow v-if="broswersizing === 'tablet'" />
					</v-col>

					<!-- 3열 -->
					<v-col
						class="pa-0"
						:class="broswersizing === 'mobile' ? 'px-0' : 'px-3'"
						md="4"
						sm="6"
					>
						<!-- 추천태그 -->
						<HomeMainNewCategory
							v-if="broswersizing !== 'tablet'"
							:items="HOME_GET_MAIN_SEARCH_TAGS"
						/>
						<!-- 마루잘알TV -->
						<HomeCommunityHomeNewMaruTv v-if="broswersizing !== 'tablet'" />
						<!-- 노하우 -->
						<HomeCommunityHomeNewKnowhow v-if="broswersizing !== 'tablet'" />
					</v-col>
				</v-row>
			</div>
		</v-col>
	</v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import common from '@/mixins/common'
import google_analytic from '@/mixins/google_analytic'

export default {
	name: 'HomeMain',
	metaInfo: {
		title: 'LIVE IN PRIDE | 동화자연마루',
		meta: [
			{
				vmid: 'title',
				property: 'og:title',
				content: 'LIVE IN PRIDE | 동화자연마루',
			},
			{
				vmid: 'keywords',
				property: 'keywords',
				content: '동화자연마루, 바닥재, 벽재, 도어, 몰딩, 주거용, 상업용, 진, 집들이, 시공 사례, 빠른견적 내기',
			},
			{
				vmid: 'description',
				property: 'og:description',
				content:
					'1948년 설립된 동화기업은 대한민국 No.1 목질 전문기업입니다. 당신의 행복한 내일을 만들겠습니다.',
			},
			{
				vmid: 'url',
				property: 'og:url',
				content: `${process.env.VUE_APP_BASIC_SERVER}`,
			},
			{
				vmid: 'og:image',
				property: 'og:image',
				content: 'https://www.greendongwha.com/main.png',
			},
		],
	},
	mixins: [common, google_analytic],
	data: () => ({
		search: false,
		model: null,
		findStoreItems: null,

		popularType: 0,
		popularTypeItems: [{ title: '바닥재' }, { title: '벽재' }],
		popularItems: [],
		videoItems: [
			{
				title: '동화자연마루 | 5월 특별 이벤트',
				video: 'https://www.youtube.com/embed/KY96s8Or0ZQ',
			},
			{
				title: '리모델링 후 전진♥류이서 부부의 신혼집은 어떤 모습일까?',
				video: 'https://www.youtube.com/embed/yk0YcuBYIz4',
			},
			{
				title: '전진♥류이서 부부가 선택한 신혼집 안방 벽장재는?',
				video: 'https://www.youtube.com/embed/giP3gA-UYYE',
			},
			{
				title: '전진♥류이서 부부, 3D 시공사례로 인테리어 간접 체험!',
				video: 'https://www.youtube.com/embed/GRZrwM4kKDs',
			},
		],

		broswersize: null,
	}),
	computed: {
		...mapGetters([
			'HOME_GET_MAIN_BANNER',
			'HOME_GET_MAIN_HOUSEWARMING_3D',
			'HOME_GET_MAIN_HOUSEWARMING_SPECIAL',
			'HOME_GET_MAIN_BEST_PRODUCT',
			'HOME_GET_MAIN_SEARCH_TAGS',
			'HOME_GET_MAIN_POPULAR_PRODUCT',
			'HOME_GET_COMMUNITY_MARU_TV_ITEMS',
			'HOME_GET_COMMUNITY_KNOW_HOW',
			'APP_GET_APP_STATE',
		]),
		selectPopularItems() {
			return this.HOME_GET_MAIN_POPULAR_PRODUCT[this.popularType]
		},
		broswersizing() {
			if (this.APP_GET_APP_WIDTH > 960) {
				return (this.broswersize = 'pc')
			}
			if (this.APP_GET_APP_WIDTH < 959 && this.APP_GET_APP_WIDTH > 601) {
				return (this.broswersize = 'tablet')
			}
			if (this.APP_GET_APP_WIDTH < 600) {
				return (this.broswersize = 'mobile')
			}
		},
	},
	watch: {},
	async created() {
		if (this.HOME_GET_MAIN_BANNER?.length === 0) await this.getBannerList()
		if (this.HOME_GET_MAIN_HOUSEWARMING_3D?.length === 0) await this.getHousewarmingList()
		if (this.HOME_GET_MAIN_BEST_PRODUCT?.length === 0) await this.getBestProductList()
		if (this.HOME_GET_MAIN_SEARCH_TAGS?.length === 0) await this.getSearchProductList()
		if (this.HOME_GET_MAIN_POPULAR_PRODUCT?.length === 0) await this.getPopularProductList()
		if (this.HOME_GET_COMMUNITY_MARU_TV_ITEMS?.length === 0) this.HOME_ACT_COMMUNITY_MARU_TV()
		if (this.HOME_GET_COMMUNITY_KNOW_HOW?.length === 0) this.HOME_ACT_COMMUNITY_KNOW_HOW({ method: 'getList' })
	},
	mounted() {
		console.log('HOME_GET_MAIN_BANNER', this.HOME_GET_MAIN_BANNER)
	},
	methods: {
		...mapActions([
			'HOME_ACT_MAIN_BANNER',
			'HOME_ACT_MAIN_HOUSEWARMING',
			'HOME_ACT_MAIN_BEST_PRODUCT',
			'HOME_ACT_MAIN_SEARCH_TAGS',
			'HOME_ACT_MAIN_POPULAR_PRODUCT',
			'HOME_ACT_COMMUNITY_MARU_TV',
			'HOME_ACT_COMMUNITY_KNOW_HOW',
		]),
		async getBannerList() {
			// main banner
			await this.HOME_ACT_MAIN_BANNER().then(res => {
				console.log('HOME_GET_MAIN_BANNER', res)
			})
		},
		async getHousewarmingList() {
			// 집들이
			await this.HOME_ACT_MAIN_HOUSEWARMING().then(res => {
				console.log('HOME_ACT_MAIN_HOUSEWARMING', res)
			})
		},
		async getBestProductList() {
			// 베스트 제품
			await this.HOME_ACT_MAIN_BEST_PRODUCT().then(res => {
				console.log('HOME_ACT_MAIN_BEST_PRODUCT', res)
			})
		},
		async getSearchProductList() {
			// 나만의 제품찾기 ( 태그 모음 )
			await this.HOME_ACT_MAIN_SEARCH_TAGS().then(res => {
				console.log('HOME_ACT_MAIN_SEARCH_TAGS', res)
			})
		},
		async getPopularProductList() {
			// 인기 제품 ( 바닥재 / 벽재 )
			await this.HOME_ACT_MAIN_POPULAR_PRODUCT().then(res => {
				console.log('HOME_ACT_MAIN_POPULAR_PRODUCT', res)
			})
		},

		onClickProduct(val) {
			console.log(val)
			this.common_link_pusher(val)
		},
		pushDwelling() {
			let link = '/product/dwelling'
			if (this.popularType === 1) link = '/product/dwelling?type=wall'
			this.$router.push(link).catch(() => {})
		},
		onClickInterior() {
			this.google_analytic_push_event('click_main_find_interior', {
				button_location: 'find_interior',
			})
			this.$router.push('/interior/store')
		},

		playVideo(item) {
			this.videoUrl = item.video_url
			this.videoDialog = true
		},
	},
}
</script>

<style scoped lang="scss">
.bg_grey {
	background-color: #f1f2f3;
}

// 메인 비주얼이미지 슬라이드
.visual_banner {
	width: 100%;
	height: 440px;
	border-radius: 10px;
}

// 카드그룹
.card_group {
	padding: 0 0 68px !important;
}

// 품질보증 , 카달로그배너
.circle_wrap {
	overflow: hidden;
	position: relative;

	border-radius: 16px;
	box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
	:hover {
		cursor: pointer;
	}
	.circle {
		position: absolute;
		right: -225px;
		top: -20px;
		width: 400px;
		height: 400px;
		background-color: #fff;
		border-radius: 50%;
		opacity: 0.5;
	}
}
.banner_quality,
.banner_cadalog {
	.tit {
		font-family: 'NotoSansKR-Bold' !important;
		font-size: 1.2rem;
		color: #262626;
	}
	.desc {
		font-family: 'NotoSansKR-Regular' !important;
		font-size: $font_normal;
		color: #666666;
	}
	.v-image {
		width: 53px;
		background-size: contain;
	}
	.v-btn {
		width: 70px;
		height: 25px;
		background-color: #42883d;
		font-family: 'NotoSansKR-Regular' !important;
		font-size: 12px;
		color: #fff;
		border-radius: 3px;
		box-shadow: none !important;
	}
}
.banner_quality {
	background-color: #fff;
	display: flex;
	align-items: center;
}
.banner_cadalog {
	background-color: #fff;
	display: flex;
	align-items: center;
	.tawr {
		text-align: left !important;
	}
	.catalog_txt {
		text-align: right !important;
	}
}

// 나만의제품찾기
.my_product {
	padding: 60px 0 !important;
	border-top: 1px solid $color_gray_4;
	.title {
		font-family: 'NotoSansKR-Regular' !important;
		font-size: 28px;
	}
}

// 유튜브
.youtube {
	padding: 60px 0 !important;
	border-top: 1px solid $color_gray_4;
	.title {
		font-family: 'NotoSansKR-Regular' !important;
		font-size: 28px;
	}
	.btn_youtube {
		width: 290px !important;
		height: 44px !important;
		border: 1px solid $color_gray_4 !important;
		border-radius: 0 !important;
		background-color: #fff !important;
		font-family: 'NotoSansKR-Regular' !important;
		font-size: 18px !important;
		.v-image {
			:deep(.v-image__image--cover) {
				background-size: initial;
			}
			min-width: 28px !important;
			width: 28px !important;
			height: 16px !important;
		}
		span.point {
			font-family: 'NotoSansKR-Bold' !important;
		}
	}
}

@media all and (min-width: 960px) and (max-width: 1263px) {
	.visual_banner {
		border-radius: 0 !important;
	}
}
@media all and (min-width: 600px) and (max-width: 959px) {
	.visual_banner {
		border-radius: 0 !important;
	}
	.my_product {
		padding: 50px 0 !important;
	}
	.youtube {
		padding: 50px 0 !important;
	}
}
@media all and (max-width: 600px) {
	.visual_banner {
		border-radius: 0 !important;
	}
	.my_product {
		padding: 30px 0 !important;
	}
	.youtube {
		padding: 30px 0 !important;
	}
	.circle_wrap {
		border-radius: 0;
	}
}
</style>
